import React from "react";
import logo from "@assets/img/logo.svg";
import { Link } from "react-router-dom";

const Logo = ({ classNames, logoUrl = logo, width, height, ...props}) => {
  return (
    <Link className={`${classNames} logo`} to="/" {...props}>
      <img src={logoUrl} alt="img" width={width} height={height} />
    </Link>
  );
};

export default Logo;
