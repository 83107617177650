import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import axios from "axios";

import { useTranslation } from "react-i18next";

import { AppContext } from "src/context/AppContext";

import { disableScroll } from "@hooks/disableScroll";
import { enableScroll } from "@hooks/enableScroll";

import Loader from "@components/_UI/Loader/Loader";

import HomePage from "@pages/HomePage";
import AboutPage from "@pages/AboutPage";
import ContactsPage from "@pages/ContactsPage";
import CasesPage from "@pages/CasesPage";

import Header from "@components/_Header";
import Burger from "@components/_Burger";
import Footer from "@components/_Footer";
import ContactUsModal from "@components/ContactUsModal";


function App() {
  const wpURL = 'https://brsys.tech-tester.ru/WP/wp-json/';
  const [burger, setBurger] = useState(false);
  const [contactUs, setContactUs] = useState(false);

  const [siteOptions, setSiteOptions] = useState([]);
  const [news, setNews] = useState([]);
  const [works, setWorks] = useState([]);
  const [team, setTeam] = useState([]);
  const [services, setServices] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);
  const [requestCounter, setRequestCounter] = useState(null);

  const { t, i18n } = useTranslation();
  const [curLng, setCurLng] = useState(i18n.language);

  const lngs = [
    {
      lng: "ru",
      text: "рус",
    },
    {
      lng: "en",
      text: "eng",
    },
    {
      lng: "kz",
      text: "қаз",
    },
  ];

  //scrollToTop
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onBurger = () => {
    setBurger(!burger);
    if (burger) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  const closeBurger = () => {
    if (burger) {
      setBurger(!burger);
      enableScroll();
    }
  };

  const onContactUsModal = () => {
    setContactUs(!contactUs);

    if(burger) {
      setBurger(false)
    }

    if (contactUs) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  const getSiteOptions = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(
        `${wpURL}acf/v3/options/options`
      );
      setSiteOptions(data.acf);
    } catch (err) {
      console.error(`произошла ошибка при загрузке текстов страниц: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  };

  const getWorks = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(`${wpURL}acf/v3/options/portfolio-options`);
      setWorks(data);
    } catch (err) {
      console.error(`произошла ошибка при загрузке новостей: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  }

  const getTeam = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(`${wpURL}acf/v3/options/portfolio-options`);
      setTeam(data?.acf?.portfolio_gallery);
    } catch (err) {
      console.error(`произошла ошибка при загрузке новостей: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  }

  const getServices = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(`${wpURL}wp-api-menus/v2/menu-locations/services`);

      setServices(data);
    } catch (err) {
      console.error(`произошла ошибка при загрузке новостей: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  }

  const getNews = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(`${wpURL}wp/v2/news`);
      setNews(data);
    } catch (err) {
      console.error(`произошла ошибка при загрузке новостей: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  };

  useEffect(() => {
    setPageLoading(true);
    disableScroll();
    getSiteOptions();
    getWorks();
    getTeam();
    getServices();
    getNews();
  }, []);

  useEffect(() => {
    if (requestCounter === 0) {
      enableScroll();
      setPageLoading(false);
    }
  }, [requestCounter]);


  return (
    <AppContext.Provider
      value={{
        wpURL,
        pageLoading,
        setPageLoading,
        setRequestCounter,
        t,
        i18n,
        curLng,
        setCurLng,
        lngs,
        burger,
        onBurger,
        closeBurger,
        contactUs,
        onContactUsModal,
        siteOptions,
        news,
        works,
        team,
        services
      }}
    >
      <div className="site-container">
        <Loader />
        <>
          <Header />
          <Burger />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/cases" element={<CasesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
          <Footer />
          <ContactUsModal />
        </>
      </div>
    </AppContext.Provider>
  );
}

export default App;
