import React, { useContext } from "react";
import MySection from "./_UI/MySection";
import { CasesPageContext } from "src/context/CasesPageContext";
import CurLngText from "./_UI/CurLngText";

const CasesHero = () => {
  const { casesOptions } = useContext(CasesPageContext);
  return (
    <MySection classNames="casesHero first-screen">
      <div className="casesHero__inner first-screen__inner">
        <div className="casesHero__info first-screen__info">
          <h3 className="casesHero__title title-section">
            <CurLngText text={casesOptions.title} />
          </h3>
          <p className="casesHero__text">
            <CurLngText text={casesOptions.text} />
          </p>
        </div>
      </div>
    </MySection>
  );
};

export default CasesHero;
