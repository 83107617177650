import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";

import MySection from "./_UI/MySection";
import MyBtn from "./_UI/MyBtn";
import { CasesPageContext } from "src/context/CasesPageContext";
import CurLngText from "./_UI/CurLngText";
const CasesAll = () => {
  const { casesOptions, casesCategories, allCases } =
    useContext(CasesPageContext);

  const [curCategory, setCurCategory] = useState("all");
  const [curCases, setCurCases] = useState(allCases);

  const handleCategory = category => {
    setCurCategory(category);
    if (category !== "all") {
      setCurCases(allCases.filter(curCase => curCase.category === category));
    } else {
      setCurCases(allCases);
    }
  };

  return (
    <MySection classNames="casesAll">
      <div className="casesAll__inner">
        <div className="casesAll__sort">
          {casesCategories?.map(item => (
            <button
              className={clsx("casesAll__sort-btn", {
                active: curCategory === item.category,
              })}
              onClick={() => handleCategory(item.category)}
              key={item.category}
            >
              <CurLngText text={item.categoryText} />
              <span>
                {item.category === "all"
                  ? allCases.length
                  : allCases.filter(
                      allCase => allCase.category === item.category
                    ).length}
              </span>
            </button>
          ))}
        </div>
        <div className="casesAll__case-outer">
          {curCases.map((curCase, i) => (
            <Link className="casesAll__case" to="#" target="_blank" key={i}>
              <img
                className="casesAll__case-img"
                loading="lazy"
                src={curCase?.image.url}
                width="390"
                height="420"
                alt="img"
              />
              <h3 className="casesAll__case-title">
                <CurLngText text={curCase.title} />
              </h3>
            </Link>
          ))}
        </div>
        {curCases.length > 10 && (
          <MyBtn classNames="casesAll__more">
            <CurLngText text={casesOptions.btn} />
          </MyBtn>
        )}
      </div>
    </MySection>
  );
};

export default CasesAll;
