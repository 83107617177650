import Logo from "@ui/Logo";
import MyBtn from "@ui/MyBtn";
import MySection from "@ui/MySection";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

function Hero() {
  const { siteOptions, onContactUsModal } = useContext(AppContext);
  const quoteText = siteOptions?.hero?.quote;
  const btnText = siteOptions?.hero?.btn;

  return (
    <MySection classNames="hero first-screen">
      <div className="hero__inner first-screen__inner">
        <Logo classNames={"hero__logo"} width="722" height="85" />
        <p className="hero__quote">
          <CurLngText text={quoteText} />
        </p>
        <MyBtn classNames="hero__btn" onClick={onContactUsModal}>
          <CurLngText text={btnText} />
        </MyBtn>
      </div>
    </MySection>
  );
}

export default Hero;
