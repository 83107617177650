import React, { useContext, useState } from "react";
import { AppContext } from "src/context/AppContext";
import { contactUsSchema } from "src/hooks/validation/contactUs";

import Modal from "./_UI/Modal/Modal";
import MyBtn from "./_UI/MyBtn";
import MyInput from "./_UI/Modal/Input";
import Textarea from "./_UI/Modal/Textarea";
import axios from "axios";
import CurLngText from "./_UI/CurLngText";

const ContactUsModal = () => {
  const { wpURL, curLng, siteOptions, contactUs, onContactUsModal } =
    useContext(AppContext);

  const modalInfo = siteOptions?.contactUsModal;

  const [values, setValues] = useState({
    uName: "",
    uEmail: "",
    uPhone: "",
    uMessage: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleBlur = e => {
    const { name } = e.target;
    contactUsSchema
      .validateAt(name, values)
      .then(() => {
        setErrors(prevErrors => ({
          ...prevErrors,
          [name]: undefined,
        }));
      })
      .catch(error => {
        setErrors(prevErrors => ({
          ...prevErrors,
          [name]: error.message,
        }));
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    contactUsSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        console.log("Данные формы валидны:", values);

        const formDataToSend = new FormData();
        for (const key in values) {
          formDataToSend.append(key, values[key]);
        }

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        axios
          .post(
            `${wpURL}contact-form-7/v1/contact-forms/34/feedback`,
            formDataToSend,
            config
          )
          .then(response => {
            console.log(formDataToSend);
            onContactUsModal();
            setValues({
              uName: "",
              uEmail: "",
              uPhone: "",
              uMessage: "",
            });
            console.log(response);
            alert("Письмо успешно отправлено!");
          })
          .catch(error => {
            alert("Ошибка при отправке письма:", error);
          });
      })
      .catch(validationErrors => {
        const errorsObj = {};
        validationErrors.inner.forEach(error => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      });
  };


  return (
    <Modal active={contactUs} onClick={onContactUsModal}>
      <h3 className="modal__title">
        <CurLngText text={modalInfo?.title} />
      </h3>
      <p className="modal__subtitle">
        <CurLngText text={modalInfo?.subtitle} />
      </p>
      <form
        className="modal__form"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <MyInput
          name={"uName"}
          value={values.uName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={
            curLng === "en"
              ? modalInfo?.name?.en
              : curLng === "kz"
              ? modalInfo?.name?.kz
              : modalInfo?.name?.ru
          }
          error={errors.uName}
        />
        <MyInput
          name={"uEmail"}
          value={values.uEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={
            curLng === "en"
              ? modalInfo?.email?.en
              : curLng === "kz"
              ? modalInfo?.email?.kz
              : modalInfo?.email?.ru}
          type={"email"}
          error={errors.uEmail}
        />
        <MyInput
          name={"uPhone"}
          value={values.uPhone}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={
            curLng === "en"
              ? modalInfo?.phone?.en
              : curLng === "kz"
              ? modalInfo?.phone?.kz
              : modalInfo?.phone?.ru}
        />
        <Textarea
          name={"uMessage"}
          value={values.uMessage}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={
            curLng === "en"
              ? modalInfo?.message?.en
              : curLng === "kz"
              ? modalInfo?.message?.kz
              : modalInfo?.message?.ru}
        />
        <MyBtn classNames={"modal__btn"} type={"submit"}>
          <CurLngText text={modalInfo?.btn} />
        </MyBtn>
      </form>
    </Modal>
  );
};

export default ContactUsModal;
