import React from "react";

const MyBtn = ({ children, classNames, angleWidth = 8, ...props }) => {
  return (
    <button className={`${classNames} btn`} {...props}>
      {children}
      <svg
        className="btn__angle"
        width={angleWidth}
        height={angleWidth}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
      </svg>
      <svg
        className="btn__angle"
        width={angleWidth}
        height={angleWidth}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
      </svg>
      <svg
        className="btn__angle"
        width={angleWidth}
        height={angleWidth}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
      </svg>
      <svg
        className="btn__angle"
        width={angleWidth}
        height={angleWidth}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
      </svg>
    </button>
  );
};

export default MyBtn;
