import React, { useContext } from 'react'
import MySection from './_UI/MySection'
import { AboutPageContext } from 'src/context/AboutPageContext'
import CurLngText from './_UI/CurLngText';

const AboutHero = () => {
  const {aboutHeroOptions} = useContext(AboutPageContext);

  return (
  <MySection classNames="aboutHero first-screen">
    <div className="aboutHero__inner first-screen__inner">
      <div className="aboutHero__info first-screen__info">
        <div className="aboutHero__title-outer">
          <p className="aboutHero__suptitle">
            <CurLngText text={aboutHeroOptions?.suptitle} />
          </p>
          <h3 className="aboutHero__title title-section">
          <CurLngText text={aboutHeroOptions?.title} />
          </h3>
        </div>
        <p className="aboutHero__text">
          <CurLngText text={aboutHeroOptions?.text} />
        </p>
      </div>
  </div>
</MySection>

  )
}

export default AboutHero
