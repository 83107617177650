import React, { useContext } from "react";
import { Autoplay, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import MySection from "./_UI/MySection";
import { AboutPageContext } from "src/context/AboutPageContext";
import CurLngText from "./_UI/CurLngText";

const AboutReviews = () => {
  const { reviewsHeadOptions, reviewsOptions } = useContext(AboutPageContext);
  return (
    <MySection classNames="aboutReviews">
      <div className="aboutReviews__inner">
        <div className="aboutReviews__head section-head">
          <p className="aboutReviews__suptitle suptitle">
            <CurLngText text={reviewsHeadOptions?.suptitle} />
          </p>
          <h3 className="aboutReviews__title title-section">
            <CurLngText text={reviewsHeadOptions?.title} />
          </h3>
        </div>

        <Swiper
          className="aboutReviews__slider swiper"
          modules={[Autoplay, Mousewheel]}
          grabCursor={true}
          speed={500}
          spaceBetween={30}
          slidesPerView={"auto"}
          loop={true}
          mousewheel={{
            forceToAxis: true,
          }}
          autoplay={{
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          {reviewsOptions?.map((item, i) => (
            <SwiperSlide className="aboutReviews__slide swiper-slide" key={`${item.name}${i}`}>
              <div className="aboutReviews__slide-content">
                <svg
                  className="aboutReviews__slide-angle"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
                </svg>
                <svg
                  className="aboutReviews__slide-angle"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
                </svg>
                <svg
                  className="aboutReviews__slide-angle"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
                </svg>
                <svg
                  className="aboutReviews__slide-angle"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
                </svg>
                <img
                  className="aboutReviews__slide-logo"
                  loading="lazy"
                  src={item.reviews_img.url}
                  width="184"
                  height="27"
                  alt="img"
                />
                <p className="aboutReviews__slide-text">
                  <CurLngText text={item.text} />
                </p>
                <div className="aboutReviews__slide-user">
                  <h6 className="aboutReviews__slide-user-name">
                    <CurLngText text={item.name} />
                  </h6>
                  <p className="aboutReviews__slide-user-position">
                    <CurLngText text={item.position} />
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </MySection>
  );
};

export default AboutReviews;
