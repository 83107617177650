import { clsx } from "clsx";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "src/context/AppContext";
import MyBtn from "./_UI/MyBtn";
import Indev from "./_UI/Indev";
import CurLngText from "./_UI/CurLngText";

const _Burger = () => {
  const { siteOptions, t, burger, onBurger, onContactUsModal } =
    useContext(AppContext);

  const contactsInfo = siteOptions?.contacts;
  const social = siteOptions?.socials;
  const navigation = siteOptions?.navigation;

  return (
    <div
      className={clsx("overlay burger__overlay", { active: burger })}
      onClick={onBurger}
    >
      <div className="burger" onClick={e => e.stopPropagation()}>
        <div className="container">
          <div className="burger__inner">
            <nav className="burger__list-outer">
              <ul className="burger__list">
                <li className="burger__list-item" onClick={onBurger}>
                  <Link className="burger__list-link" to={navigation?.cases.link}>
                    <CurLngText text={navigation?.cases} />
                  </Link>
                </li>
                <li className="burger__list-item">
                  <Indev classNames="burger__list-link indev-link">
                    <CurLngText text={navigation?.team} />
                  </Indev>
                </li>
                <li className="burger__list-item" onClick={onBurger}>
                  <Link className="burger__list-link" to={navigation?.about.link}>
                    <CurLngText text={navigation?.about} />
                  </Link>
                </li>
                <li className="burger__list-item" onClick={onBurger}>
                  <Link className="burger__list-link" to={navigation?.contacts.link}>
                    <CurLngText text={navigation?.contacts} />
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="burger__info">
              <ul className="burger__contacts">
                <li className="burger__contacts-item">
                  <Link
                    to={`tel:${contactsInfo?.phone.replace(/[ ()-]/g, "")}`}
                  >
                    {contactsInfo?.phone}
                  </Link>
                </li>
                <li className="burger__contacts-item">
                  <CurLngText text={contactsInfo?.address} />
                </li>
                <li className="burger__contacts-item">
                  <Link to={`mailto:${contactsInfo?.email}`}>
                    {contactsInfo?.email}
                  </Link>
                </li>
                <li className="burger__contacts-item">
                  <CurLngText text={contactsInfo?.street} />
                </li>
              </ul>
              <ul className="burger__social">
                <li className="burger__social-item">
                  <Link className="burger__social-link" to={social?.linkedin} target="_blank">
                    linkedin
                  </Link>
                </li>
                <li className="burger__social-item">
                  <Link className="burger__social-link" to={social?.instagram} target="_blank">
                    instagram
                  </Link>
                </li>
                <li className="burger__social-item">
                  <Link className="burger__social-link" to={social?.behance} target="_blank">
                    behance
                  </Link>
                </li>
              </ul>
            </div>
            <MyBtn classNames="burger__btn" onClick={onContactUsModal}>
              {t("workWithUsBtn")}
            </MyBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default _Burger;
