import imgUrl from "@assets/img/about_img.jpg";
import MyBtn from "@ui/MyBtn";
import MySection from "@ui/MySection";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";
import { Link } from "react-router-dom";

const About = () => {
  const {siteOptions} = useContext(AppContext)

  const about = siteOptions?.home_about;
  return (
    <MySection classNames="about">
      <div className="about__inner">
        <div className="about__head">
          <p className="about__suptitle suptitle">
            <CurLngText text={about?.suptitle} />
          </p>
          <h3 className="about__title title-section">
            <CurLngText text={about?.title} />
          </h3>
        </div>
        <div className="about__info">
          <p className="about__info-text">

          <CurLngText text={about?.text} />
          </p>
          <p className="about__info-text">

          <CurLngText text={about?.text_2} />
          </p>
          <div className="about__img-outer">
            <img
              className="about__img"
              loading="lazy"
              src={imgUrl}
              width="363"
              height="468"
              alt="img"
            />
          </div>
        </div>
        <Link to={'/contacts'}>
        <MyBtn classNames="about__btn">
          <CurLngText text={about?.btn} />
        </MyBtn>
        </Link>
      </div>
    </MySection>
  );
};

export default About;
