import React, { useContext } from "react";
import Logo from "@ui/Logo";
import { Link } from "react-router-dom";
import MyBtn from "./_UI/MyBtn";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

const Footer = () => {
  const { t, siteOptions, onContactUsModal } = useContext(AppContext);

  const contactsInfo = siteOptions?.contacts;
  const social = siteOptions?.socials;
  const navigation = siteOptions?.navigation;
  const privacyPolicy = siteOptions?.privacy_policy;
  const userAgreement = siteOptions?.user_agreement;

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__high">
            <div className="footer__logo-outer">
              <Logo classNames={"footer__logo"} width={"495"} height={"150"} />
              <p className="footer__city">
                <CurLngText text={contactsInfo?.address} />
              </p>
            </div>
            <nav className="footer__list-outer">
              <ul className="footer__list">
                <li className="footer__list-item">
                  <h3 className="footer__list-title">
                    <CurLngText text={social?.title} />
                  </h3>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={social?.linkedin}
                    target="_blank"
                  >
                    linkedin
                  </Link>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={social?.instagram}
                    target="_blank"
                  >
                    instagram
                  </Link>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={social?.behance}
                    target="_blank"
                  >
                    behance
                  </Link>
                </li>
              </ul>
              <ul className="footer__list">
                <li className="footer__list-item">
                  <h3 className="footer__list-title">
                    <CurLngText text={navigation?.title} />
                  </h3>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={navigation?.home.link}
                  >
                    <CurLngText text={navigation?.home} />
                  </Link>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={navigation?.cases.link}
                  >
                    <CurLngText text={navigation?.cases} />
                  </Link>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={navigation?.about.link}
                  >
                    <CurLngText text={navigation?.about} />
                  </Link>
                </li>
                <li className="footer__list-item">
                  <Link
                    className="footer__list-link"
                    to={navigation?.contacts.link}
                  >
                    <CurLngText text={navigation?.contacts} />
                  </Link>
                </li>
              </ul>
            </nav>
            <MyBtn
              classNames={"footer__btn"}
              angleWidth={8}
              onClick={onContactUsModal}
            >
              {t("workWithUsBtn")}
            </MyBtn>
          </div>
          <div className="footer__low">
            <Link className="footer__policy" to={privacyPolicy?.link}>
              <CurLngText text={privacyPolicy?.text} />
            </Link>
            <Link className="footer__policy" to={userAgreement?.link}>
              <CurLngText text={userAgreement?.text} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
