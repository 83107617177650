import React from "react";
import cl from './Modal.module.scss'

const Close = ({onClick, ...props}) => {
  return (
    <button className={cl.close} onClick={onClick} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="29"
        viewBox="0 0 45 29"
        fill="none"
      >
        <path d="M15.3184 7.23022L30.0319 21.9437" strokeWidth="1.03846" />
        <path d="M15.4453 21.8943L30.1588 7.18079" strokeWidth="1.03846" />
      </svg>
    </button>
  );
};

export default Close;
