import { useContext, useEffect, useState } from "react";
import { AboutPageContext } from "src/context/AboutPageContext";
import { AppContext } from "src/context/AppContext";
import axios from "axios";
import { disableScroll } from "src/hooks/disableScroll";

import MyPage from "@components/_UI/MyPage";
import AboutHero from "@components/AboutHero";
import AboutReviews from "@components/AboutReviews";
import AboutText from "@components/AboutText";
import AboutVideo from "@components/AboutVideo";
import Contacts from "@components/Contacts";
import News from "@components/News";
import Separator from "@components/Separator";

const AboutPage = () => {
  const { curLng, wpURL, setPageLoading, setRequestCounter } = useContext(AppContext);

  const [aboutHeroOptions, setAboutHeroOptions] = useState();
  const [aboutTextOptions, setAboutTextOptions] = useState();
  const [reviewsHeadOptions, setReviewsHeadOptions] = useState();
  const [reviewsOptions, setReviewsOptions] = useState();

  const getAboutInfo = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(`${wpURL}acf/v3/options/options`);

      setAboutHeroOptions(data?.acf?.aboutPageHero);
      setAboutTextOptions(data?.acf?.aboutPageText);
      setReviewsHeadOptions(data?.acf?.reviews_head);
      setReviewsOptions(data?.acf?.reviews);
    } catch (err) {
      console.error(`произошла ошибка при загрузке текстов страниц: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  };

  useEffect(() => {
    setPageLoading(true);
    disableScroll();

    getAboutInfo();
  }, []);

  return (
    <AboutPageContext.Provider
      value={{
        aboutHeroOptions,
        aboutTextOptions,
        reviewsHeadOptions,
        reviewsOptions,
      }}
    >
      <MyPage
        hiddenTitle={
          'Страница "о нас", где вы можете узанть получше миссию, работников и жизнь студии веб-разработки BRSYS'
        }
        metaDescr='Страница "О Нас", где Вы можете познакомиться с командой студии веб-разработки BRSYS'
        metaTitle={curLng === "en" ? "BRSYS: About Company" : curLng === "kz" ? "BRSYS: О Компании" : "BRSYS: О Компании"}
      >
        <AboutHero />
        <Separator />
        <AboutText />
        <Separator />
        <AboutVideo />
        <Separator />
        <AboutReviews />
        <Separator />
        <News />
        <Separator />
        <Contacts />
        <Separator />
      </MyPage>
    </AboutPageContext.Provider>
  );
};

export default AboutPage;
