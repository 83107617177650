import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoJS2 = ({ classNames, videoUrl, posterUrl }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const player = videojs(videoRef.current, { poster: posterUrl });
      player.src(videoUrl);

      playerRef.current = player; // Сохраняем ссылку на объект плеера для дальнейшего использования

      return () => {
        if (playerRef.current) {
          playerRef.current.dispose(); // Освобождаем ресурсы Video.js при размонтировании компонента
        }
      };
    }
  }, [videoUrl, posterUrl]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className={`${classNames} video-js vjs-default-skin`} controls></video>
    </div>
  );
};

export default VideoJS2;
