import MyPage from "@components/_UI/MyPage";
import ContactsHero from "@components/ContactsHero";
import LearnMore from "@components/LearnMore";
import Separator from "@components/Separator";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";

const ContactsPage = () => {
  const {curLng} = useContext(AppContext)
  return (
    <MyPage
      hiddenTitle={
        "Страница контактов, где Вы можете связаться со студией веб-разработки BRSYS и понять, какой сайт вам нужно разработать"
      }
      metaDescr="Страница контактов, где Вы можете связаться со студией веб-разработки BRSYS и понять, какой сайт вам нужно разработать, например: landing page, интернет-магазин, сайт-визитка, SPA"

      metaTitle={curLng === "en" ? "BRSYS: Contacts" : curLng === "kz" ? "BRSYS: Контакты" : "BRSYS: Контакты"}
    >
      <ContactsHero />
      <Separator />
      <LearnMore
        classNames={"contactsLearnMore"}
        innerClassNames={"contactsLearnMore__inner"}
      />
    </MyPage>
  );
};

export default ContactsPage;
