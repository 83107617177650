import React, { useContext } from 'react'
import { AppContext } from 'src/context/AppContext'

const Indev = ({children, classNames, ...props}) => {
  const {t} = useContext(AppContext)
  return (
    <div className={`${classNames} indev`} {...props}>
      <span>{t('indev')}</span>
      {children}
    </div>
  )
}

export default Indev
