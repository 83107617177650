import { Link } from "react-router-dom";
import MyBtn from "./_UI/MyBtn";
import MySection from "./_UI/MySection";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

function Works() {
  const { siteOptions, works } = useContext(AppContext);
  return (
    <MySection classNames="works">
      <div className="works__inner">
        <div className="head section-head">
          <p className="works__suptitle suptitle">
            <CurLngText text={siteOptions?.home_cases?.suptitle} />
          </p>
          <h3 className="works__title title-section">
            <CurLngText text={siteOptions?.home_cases?.title} />
          </h3>
        </div>
        <div className="works__block-outer">
          <div className="works__block">
            <Link to={works.acf?.portfolio_projects[0].link} target="_blank">
              <p className="works__suptitle suptitle">
                <CurLngText text={works.acf?.portfolio_projects[0].title} />
              </p>
              <img
                className="works__img"
                loading="lazy"
                src={works.acf?.portfolio_projects[0].image.url}
                width="294"
                height="323"
                alt="img"
              />
            </Link>
          </div>
          <div className="works__block">
            <Link to={works.acf?.portfolio_projects[1].link} target="_blank">
              <p className="works__suptitle suptitle">
                <CurLngText text={works.acf?.portfolio_projects[1].title} />
              </p>
              <img
                className="works__img"
                loading="lazy"
                src={works.acf?.portfolio_projects[1].image.url}
                width="294"
                height="323"
                alt="img"
              />
            </Link>
          </div>
          <div className="works__block">
            <Link to={works.acf?.portfolio_projects[2].link} target="_blank">
              <p className="works__suptitle suptitle">
                <CurLngText text={works.acf?.portfolio_projects[2].title} />
              </p>
              <img
                className="works__img"
                loading="lazy"
                src={works.acf?.portfolio_projects[2].image.url}
                width="294"
                height="323"
                alt="img"
              />
            </Link>
          </div>
        </div>
        <Link className="works__btn-outer" to={"/cases"}>
          <MyBtn classNames="works__btn">
            <CurLngText text={siteOptions?.home_cases?.btn} />
          </MyBtn>
        </Link>
      </div>
    </MySection>
  );
}

export default Works;
