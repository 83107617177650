import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    debug: true,
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          burger: {
            nav: {
              cases: 'Cases',
              team: 'Team',
              about: 'About',
              contacts: 'Contacts',
            },
            contacts: {
              tel: '+7 (708) 283 90 13',
              telLink: '+77082839013',
              location: 'Kazakhstan, c. Ust-Kamenogorsk',
              mail: 'hello@brsys.com',
              address: 'Baitursynova st., 90A',
            },
          },
          hero: {
            quote: 'turning problems into innovative benefits with the help of it-technologies',
            btn: "Start Work",
          },
          works: {
            suptitle: 'cases',
            title: 'our digital masterpieces',
          },
          cases: {
            hero: {
              title: 'We develop complex and large-scale projects',
              text: 'At the heart of each of our projects is a creative idea, and we have We have enough resources not only to come up with it, but also to realize it.',
            }
          },
          contactUsBtn: "contact us",
          workWithUsBtn: "work with us",
          more: 'see more',
          indev: 'Page in development :)',
        },
      },
      ru: {
        translation: {
          burger: {
            nav: {
              cases: 'Кейсы',
              team: 'Команда',
              about: 'о компании',
              contacts: 'контакты',
            },
            contacts: {
              tel: '+7 (708) 283 90 13',
              telLink: '+77082839013',
              location: 'Казахстан, г. Усть-Каменогорск',
              mail: 'hello@brsys.com',
              address: 'ул. Байтурсынова, 90А',
            },
          },
          hero: {
            quote: `перевоплощение проблем в инновационные выгоды с помощью it-технологий`,
            btn: "ОБСУДИТЬ ПРОЕКТ",
          },
          works: {
            suptitle: 'проекты',
            title: 'наши цифровые шедевры',
          },
          cases: {
            hero: {
              title: 'Разрабатываем cложные и масштабные проекты',
              text: `В основе каждого из наших проектов лежит креативная идея, и у нас
              достаточно ресурсов, чтобы не только ее придумать, но и реализовать.`,
            }
          },
          contactUsBtn: "связаться с нами",
          workWithUsBtn: "обсудить проект",
          more: 'смотреть больше',
          indev: "Страница в разработке :)",
        },
      },
      kz: {
        translation: {
          burger: {
            nav: {
              cases: 'жобалар',
              team: 'Команда',
              about: 'біз туралы',
              contacts: 'байланыс',
            },
            contacts: {
              tel: '+7 (708) 283 90 13',
              telLink: '+77082839013',
              location: 'Казахстан, г. Усть-Каменогорск',
              mail: 'hello@brsys.com',
              address: 'ул. Байтурсынова, 90А',
            },
          },
          hero: {
            quote: `технологиялардың көмегімен мәселелерді инновациялық пайдаға айналдыру`,
            btn: "жұмысты бастау",
          },
          works: {
            suptitle: 'жобалар',
            title: 'біздің сандық шедеврлер',
          },
          cases: {
            hero: {
              title: 'Біз күрделі және ауқымды жобаларды әзірлейміз',
              text: 'Біздің әрбір жобамыздың негізінде шығармашылық идея жатыр және бізде оны ойлап тауып қана қоймай, оны жүзеге асыруға жеткілікті ресурстар бар.',
            }
          },
          contactUsBtn: "бізбен байланыс",
          workWithUsBtn: "работать с нами",
          more: 'көбірек қараңыз',
          indev: 'Әзірлеудегі бет :)',
        },
      },
    },
  });

export default i18n;
