import React, { useContext } from "react";
import MySection from "./_UI/MySection";
import MyBtn from "./_UI/MyBtn";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";
import { Link } from "react-router-dom";

const ContactsHero = () => {
  const { t, siteOptions, onContactUsModal } = useContext(AppContext);

  const contactsInfo = siteOptions?.contacts;

  return (
    <MySection classNames="contactsHero first-screen">
      <div className="contactsHero__inner first-screen__inner">
        <div className="contactsHero__head">
          <p className="contactsHero__suptitle suptitle">
            <CurLngText text={contactsInfo?.title} />
          </p>
          <div className="contactsHero__title-outer">
            <h3 className="contactsHero__title">
              hello<span>(a)</span>brsys.kz
            </h3>
            <p className="contactsHero__subtitle">
              <CurLngText text={contactsInfo?.subtitle} />
            </p>
          </div>
          <MyBtn classNames="contactsHero__feedback btn-bg btn-svg" onClick={onContactUsModal}>
          {t("contactUsBtn")}
          </MyBtn>
        </div>
        <div className="contactsHero__info">
          <ul className="contactsHero__list">
            <li className="contactsHero__list-item">
              <Link to={`tel:${contactsInfo?.phone.replace(/[ ()-]/g, "")}`}>
                {contactsInfo?.phone}
              </Link>
            </li>
            <li className="contactsHero__list-item">
              <CurLngText text={contactsInfo?.address} />
            </li>
            <li className="contactsHero__list-item">
              <Link to={`mailto:${contactsInfo?.email}`}>
                {contactsInfo?.email}
              </Link>
            </li>
            <li className="contactsHero__list-item">
              <CurLngText text={contactsInfo?.street} />
            </li>
          </ul>
        </div>
      </div>
    </MySection>
  );
};

export default ContactsHero;
