import About from "@components/About";
import Contacts from "@components/Contacts";
import LearnMore from "@components/LearnMore";
import News from "@components/News";
import Separator from "@components/Separator";
import Team from "@components/Team";
import Works from "@components/Works";
import Hero from "@components/Hero";
import Services from "@components/Services";
import MyPage from "@components/_UI/MyPage";
import Advantages from "@components/Advantages";

const HomePage = () => {
  return (
    <MyPage metaTitle="Заказать разработку сайтов любой сложности в компании BRSYS" hiddenTitle={'Заказать разработку сайтов любой сложности в компании BRSYS'} metaDescr='студия веб-разработки brsys.kz разработает для вас сайт или веб-приложение любой сложности, например: landing page, интернет-магазин, сайт-визитка, SPA'>
      <Hero />
      <Separator />
      <Advantages />
      <Separator />
      <Works />
      <Separator />
      <About />
      <Separator />
      <Team />
      <Separator />
      <Services />
      <Separator />
      <LearnMore />
      <News />
      <Separator />
      <Contacts />
    </MyPage>
  );
};

export default HomePage;
