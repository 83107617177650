import React, { useContext } from "react";
import MySection from "./_UI/MySection";
import { AboutPageContext } from "src/context/AboutPageContext";
import CurLngText from "./_UI/CurLngText";

const AboutText = () => {
  const { aboutTextOptions } = useContext(AboutPageContext);
  return (
    <MySection classNames="aboutText">
      <div className="aboutText__inner">
        <p className="aboutText__text">
          <CurLngText text={aboutTextOptions?.text} />
        </p>
        <h3 className="aboutText__title">
          <CurLngText text={aboutTextOptions?.title} />
        </h3>
      </div>
    </MySection>
  );
};

export default AboutText;
