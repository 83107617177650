import React from "react";
import MySection from "./_UI/MySection";
import videoUrl1 from "@assets/video/mov_bbb.mp4";
import poster1 from "@assets/img/video-1.jpg";
import VideoJS2 from "./VideoJS2";

const AboutVideo = () => {
  return (
    <MySection classNames="aboutVideo">
      <div className="aboutVideo__inner">
        <VideoJS2
          classNames={"aboutVideo__video"}
          videoUrl={videoUrl1}
          posterUrl={poster1}
          width="600"
          height="420"
          id={'aboutVideo2'}
        />
      </div>
    </MySection>
  );
};

export default AboutVideo;
