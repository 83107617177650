import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";

import MyBtn from "@ui/MyBtn";
import MySection from "@ui/MySection";
import Indev from "./_UI/Indev";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

function Team() {
  const {siteOptions, team} = useContext(AppContext);

  const teamInfo = siteOptions?.home_team;

  return (
    <MySection classNames="team">
      <div className="team__inner">
        <div className="team__head section-head">
          <p className="team__suptitle suptitle section-suptitle">
            <CurLngText text={teamInfo?.suptitle} />
          </p>
          <h3 className="team__title title-section section-title">
            <CurLngText text={teamInfo?.title} />
          </h3>
        </div>

        <Swiper
          className="team__slider swiper"
          modules={[ Mousewheel]}
          grabCursor={true}
          speed={500}
          spaceBetween={50}
          slidesPerView={"auto"}
          mousewheel={{
            forceToAxis: true,
          }}
        >
          {
            team.map((dude, i) => (
              <SwiperSlide className="team__slide swiper-slide" key={`${dude?.name.en}${i}`}>
                <div className="team__slide-content">
                  <img
                    className="team__slide-img"
                    loading="lazy"
                    src={dude?.image.url}
                    width="275"
                    height="344"
                    alt="img"
                  />
                  <div className="team__slide-info">
                    <h5 className="team__slide-name">
                      <CurLngText text={dude?.name} />
                    </h5>
                    <p className="team__slide-position">{
                      dude?.position
                    }</p>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Indev classNames="team__indev">
          <MyBtn classNames="team__btn">
            <CurLngText text={teamInfo?.btn} />
          </MyBtn>
        </Indev>
      </div>
    </MySection>
  );
}

export default Team;
