import MyPage from "@components/_UI/MyPage";
import CasesAll from "@components/CasesAll";
import CasesBest from "@components/CasesBest";
import CasesHero from "@components/CasesHero";
import Contacts from "@components/Contacts";
import Separator from "@components/Separator";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/context/AppContext";
import { CasesPageContext } from "src/context/CasesPageContext";
import { disableScroll } from "src/hooks/disableScroll";

const CasesPage = () => {
  const { curLng, wpURL, setPageLoading, setRequestCounter } = useContext(AppContext);
  const [casesOptions, setCasesOptions] = useState([]);
  const [bestCases, setBestCases] = useState([]);
  const [allCases, setAllCases] = useState([]);
  const [casesCategories, setCasesCategories] = useState([]);

  const getCasesInfo = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(`${wpURL}acf/v3/options/options`);
      setCasesOptions(data?.acf?.casesPage);
    } catch (err) {
      console.error(`произошла ошибка при загрузке текстов страниц: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  };

  const getCasesPortfolio = async () => {
    setRequestCounter(prev => prev + 1);
    try {
      const { data } = await axios.get(
        `${wpURL}acf/v3/options/portfolio-options`
      );
      setBestCases(data.acf.portfolio_projects_best);
      setAllCases(data.acf.portfolio_projects);
      setCasesCategories(data.acf.portfolio_cases_categories);
    } catch (err) {
      console.error(`произошла ошибка при загрузке текстов страниц: ${err}`);
    } finally {
      setRequestCounter(prev => prev - 1);
    }
  };

  useEffect(() => {
    setPageLoading(true);
    disableScroll();

    getCasesInfo();
    getCasesPortfolio();
  }, []);

  return (
    <CasesPageContext.Provider
      value={{ casesOptions, bestCases, allCases, casesCategories }}
    >
      <MyPage
        hiddenTitle={"Страница проектов студии веб-разработки BRSYS"}
        metaDescr="Страница проектов, где Вы можете посмотреть примеры сайтов и оценить качество работы студии веб-разработки BRSYS и понять, какой сайт вам нужно разработать, например: landing page, интернет-магазин, сайт-визитка, SPA"

        metaTitle={curLng === "en" ? "BRSYS: Cases" : curLng === "kz" ? "BRSYS: Кейсы" : "BRSYS: Кейсы"}
      >
        <CasesHero />
        <Separator />
        <CasesBest />
        <Separator />
        {allCases.length > 1 && <CasesAll />}
        <Separator />
        <Contacts />
      </MyPage>
    </CasesPageContext.Provider>
  );
};

export default CasesPage;
