import { Link } from "react-router-dom";
import MySection from "./_UI/MySection";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

const LearnMore = ({classNames, innerClassNames}) => {
  const {siteOptions} = useContext(AppContext);

  const social = siteOptions?.socials;

  return (
    <MySection classNames={`${classNames} learnMore`}>
        <div className={`${innerClassNames} learnMore__inner`}>
          <h3 className="learnMore__title">
            <CurLngText text={siteOptions?.learnMore?.title} />
          </h3>
          <div className="learnMore__link-outer">
            <Link className="learnMore__link" to={social?.linkedin} target="_blank">
              linkedin
            </Link>
            <Link className="learnMore__link" to={social?.instagram} target="_blank">
              instagram
            </Link>
            <Link className="learnMore__link" to={social?.behance} target="_blank">
              behance
            </Link>
          </div>
        </div>
    </MySection>
  );
};

export default LearnMore;
