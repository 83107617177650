import React from "react";

const MySection = ({ children, classNames }) => {
  return (
    <section className={`${classNames} section`}>
      <div className="container">{children}</div>
    </section>
  );
};

export default MySection;
