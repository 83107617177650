import { FreeMode, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import MySection from "./_UI/MySection";
import { useContext } from "react";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

function News() {
  const { news, siteOptions } = useContext(AppContext);

  const newsInfo = siteOptions?.home_news;
  return (
    <MySection classNames="news">
      <div className="news__inner">
        <div className="news__head section-head">
          <p className="news__suptitle suptitle section-suptitle">
            <CurLngText text={newsInfo?.suptitle} />
          </p>
          <h3 className="news__title title-section section-title">
            <CurLngText text={newsInfo?.title} />
          </h3>
        </div>

        <Swiper
          className="news__slider swiper"
          modules={[ FreeMode, Mousewheel]}
          grabCursor={true}
          speed={500}
          spaceBetween={30}
          slidesPerView={"auto"}
          loop={true}
          mousewheel={{
            forceToAxis: true,
          }}
          freeMode={{
            enabled: true,
            sticky: true,
            minimumVelocity: 0.3,
          }}
        >
          {news?.map(obj => (
            <SwiperSlide className="news__slide swiper-slide" key={obj.id}>
              <div className="news__slide-content">
                <img
                  className="news__slide-img"
                  loading="lazy"
                  src={obj.acf.preview_image.url}
                  width={"297"}
                  height="427"
                  alt="img"
                />
                <p className="news__slide-text">{obj.acf.text_under_image}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </MySection>
  );
}

export default News;
