import React, { useContext } from "react";
import MySection from "./_UI/MySection";
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import MyBtn from "./_UI/MyBtn";
import { CasesPageContext } from "src/context/CasesPageContext";
import CurLngText from "./_UI/CurLngText";

const CasesBest = () => {
  const { bestCases } = useContext(CasesPageContext);

  return (
    <MySection classNames="casesBest">
      <div className="casesBest__inner">
        {window.innerWidth >= 1280 ? (
          <div className="casesBest__block-outer">
            {bestCases.map(bestCase => (
              <div className="casesBest__block" key={bestCase.title.en}>
                <span
                  className="casesBest__block-before"
                  style={{ background: bestCase.title.bg }}
                ></span>
                <h3 className="casesBest__block-title">
                  <CurLngText text={bestCase.title} />
                </h3>
                <img
                  className="casesBest__block-img"
                  loading="lazy"
                  src={bestCase.image.url}
                  width="389"
                  height="263"
                  alt="img"
                />
                <div className="casesBest__block-info">
                  <p className="casesBest__block-text">
                    <CurLngText text={bestCase.text} />
                  </p>

                  <Link to={bestCase.link.link} target="_blank">
                    <MyBtn classNames="casesBest__block-btn btn-bg">
                      <CurLngText text={bestCase.link} />
                    </MyBtn>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Swiper
            className="casesBest__slider swiper"
            modules={[Pagination]}
            slidesPerView={1}
            spaceBetween={20}
            speed={500}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
          >
            {bestCases.map(bestCase => (
              <SwiperSlide
                className="casesBest__block swiper-slide"
                key={bestCase.title.en}
                style={{ background: bestCase.title.bg }}
              >
                <h4 className="casesBest__block-title">
                  <CurLngText text={bestCase.title} />
                </h4>
                <img
                  className="casesBest__block-img"
                  loading="lazy"
                  src={bestCase.image.url}
                  width="389"
                  height="263"
                  alt="img"
                />
                <div className="casesBest__block-info">
                  <p className="casesBest__block-text">
                    <CurLngText text={bestCase.text} />
                  </p>

                  <Link
                    to={bestCase.link.link}
                    target="_blank"
                    className="casesBest__block-btn-outer"
                  >
                    <MyBtn classNames="casesBest__block-btn btn-bg">
                      <CurLngText text={bestCase.link} />
                    </MyBtn>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {/* <div className="casesBest__slider-pagination swiper-pagination"></div> */}
      </div>
    </MySection>
  );
};

export default CasesBest;
