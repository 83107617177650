import React from "react";
import cl from "./Modal.module.scss";
import errorIcon from "@assets/img/field-error.svg";

const Input = ({
  error,
  value,
  onChange,
  classNames,
  placeholder = "Type some..",
  type = "text",
  ...props
}) => {
  return (
    <label className={error ? cl.label + " " + cl.error : cl.label}>
      <input
        className={`${cl.input} ${classNames}`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        {...props}
      />

      {error && (
        <img
          className={cl.error__field}
          src={errorIcon}
          width={18}
          height={18}
          alt="img"
        />
      )}
    </label>
  );
};

export default Input;
