import { useContext } from "react";
import MySection from "./_UI/MySection";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";

const Services = () => {
  const { siteOptions } = useContext(AppContext);

  const servicesInfo = siteOptions?.home_services;
  const servicesSites = siteOptions?.services_sites;


  return (
    <MySection classNames="services">
      <div className="services__inner">
        <div className="services__head section-head">
          <p className="services__suptitle suptitle section-suptitle">
            <CurLngText text={servicesInfo?.suptitle} />
          </p>
          <h3 className="services__title title-section section-title">
            <CurLngText text={servicesInfo?.title} />
          </h3>
        </div>
        <div className="services__block-outer">
          <div className="services__block">
            <svg
              className="services__block-angle"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>

            <svg
              className="services__block-angle"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>

            <svg
              className="services__block-angle"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>

            <svg
              className="services__block-angle"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>
            <ul className="services__list">
              <li className="services__list-item">
              <CurLngText text={servicesInfo?.sites} />
              </li>
              {
                servicesSites?.map((el, i) => (
                  <li className="services__list-item" key={i}>
                    <CurLngText text={el.item} />
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </MySection>
  );
};

export default Services;
