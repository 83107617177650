import React from "react";
import { Helmet } from "react-helmet";

const MyPage = ({
  children,
  metaTitle = "BRSYS",
  metaDescr = "студия веб-разработки brsys.kz разработает для вас сайт или веб-приложение любой сложности, например: landing page, интернет-магазин, сайт-визитка, SPA",
  hiddenTitle,
  classNames,
}) => {
  return (
    <main className={`${classNames} main`}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescr} />
      </Helmet>
      <h1 className="title-hidden">{hiddenTitle}</h1>
      {children}
    </main>
  );
};

export default MyPage;
