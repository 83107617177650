import React, { useContext } from "react";
import { AppContext } from "src/context/AppContext";

const CurLngText = ({ text }) => {
  const { curLng } = useContext(AppContext);
  return (
    <>{curLng === "en" ? text?.en : curLng === "kz" ? text?.kz : text?.ru}</>
  );
};

export default CurLngText;
