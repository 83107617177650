import { useContext } from "react";
import MySection from "./_UI/MySection";
import { AppContext } from "src/context/AppContext";
import CurLngText from "./_UI/CurLngText";
import { Link } from "react-router-dom";

const Contacts = () => {
  const { siteOptions } = useContext(AppContext);

  const contactsInfo = siteOptions?.contacts;

  return (
    <MySection classNames="contacts">
      <div className="contacts__inner">
        <div className="contacts__head section-head">
          <p className="contacts__suptitle suptitle">
            <CurLngText text={contactsInfo?.title} />
          </p>
          <h3 className="contacts__title">
            hello<span>(a)</span>brsys.kz
          </h3>
        </div>
        <div className="contacts__info">
          <ul className="contacts__list">
            <li className="contacts__list-item">
              <Link to={`tel:${contactsInfo?.phone.replace(/[ ()-]/g, "")}`}>
              {contactsInfo?.phone}
              </Link>
            </li>
            <li className="contacts__list-item">
              <CurLngText text={contactsInfo?.address} />
            </li>
            <li className="contacts__list-item">
              <Link to={`mailto:${contactsInfo?.email}`}>
                {contactsInfo?.email}
              </Link>
            </li>
            <li className="contacts__list-item">
              <CurLngText text={contactsInfo?.street} />
            </li>
          </ul>
        </div>
      </div>
    </MySection>
  );
};

export default Contacts;
