import React from "react";
import cl from "./Modal.module.scss";
import { clsx } from "clsx";
import Close from "./Close";

const Modal = ({ children, active, onClick, ...props }) => {
  return (
    <div className={clsx(cl.overlay, {[cl.active]: active})} onClick={onClick} {...props}>
      <div className={cl.modal} onClick={(e) => e.stopPropagation()}>
        <Close onClick={onClick} />
        <div className={cl.inner}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
