import React, { useContext } from "react";
import { Transition } from "react-transition-group";
import cl from "./Loader.module.scss";
import { AppContext } from "src/context/AppContext";

const Loader = () => {
  const { pageLoading } = useContext(AppContext);

  const duration = 500;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition in={pageLoading} timeout={duration} mountOnEnter unmountOnExit>
      {state => (
        <div
          className={cl.loader__overlay}
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          <div className={`container ${cl.container}`}>
            <div className={`${cl.loader} loader`}>
              <svg
                className={cl.svg}
                width="640"
                height="129"
                viewBox="0 0 722 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M225.344 81.5161H266.285C273.253 81.2036 289.63 77.0171 299.386 62.7702C305.497 53.8465 311.926 48.6246 316.877 45.8147C321.193 43.3651 326.268 43.087 331.231 43.087H378.654"
                  stroke="white"
                  strokeWidth="13.9373"
                  className="svg-elem-1"
                ></path>
                <path
                  d="M558.395 81.5161H599.335C606.304 81.2036 622.68 77.0171 632.436 62.7702C638.547 53.8465 644.977 48.6246 649.928 45.8147C654.244 43.3651 659.319 43.087 664.281 43.087H711.705"
                  stroke="white"
                  strokeWidth="13.9373"
                  className="svg-elem-2"
                ></path>
                <path
                  d="M9.31641 26.7838V64.9957C10.0928 70.5025 14.7896 81.5161 27.3664 81.5161C39.9432 81.5161 76.082 81.5161 101.313 81.5161C110.629 81.5161 122.274 77.9061 122.274 63.4661C122.274 49.0261 112.376 43.6693 101.313 43.6693H30.2777"
                  stroke="white"
                  strokeWidth="13.9742"
                  className="svg-elem-3"
                ></path>
                <path
                  d="M149.059 88.5032V60.351C149.923 51.2693 154.89 42.3896 167.926 43.1304H259.688"
                  stroke="white"
                  strokeWidth="13.9373"
                  className="svg-elem-4"
                ></path>
                <path
                  d="M401.758 36.1V56.0293C402.534 61.6344 407.231 72.8446 419.808 72.8446C432.385 72.8446 485.991 72.8446 511.222 72.8446"
                  stroke="white"
                  strokeWidth="13.9742"
                  className="svg-elem-5"
                ></path>
                <path
                  d="M533.348 36.1V85.6621C532.414 91.2672 526.768 102.477 511.649 102.477C496.53 102.477 432.088 102.477 401.757 102.477"
                  stroke="white"
                  strokeWidth="13.9742"
                  className="svg-elem-6"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Loader;
