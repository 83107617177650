import sepImg1Url from "@assets/img/arrow-deg.svg";
import sepImg2Url from "@assets/img/plus.svg";

const Separator = () => {
  return (
    <div className="separator">
      <div className="container">
        <div className="separator__inner">
          <img
            className="separator__img"
            loading="lazy"
            src={sepImg1Url}
            width="25"
            height="25"
            alt="img"
          />
          <img
            className="separator__img"
            loading="lazy"
            src={sepImg2Url}
            width="25"
            height="25"
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default Separator;
