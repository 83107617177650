import React, { useContext, useState } from "react";
import Logo from "./_UI/Logo";
import clsx from "clsx";
import { AppContext } from "src/context/AppContext";
import logoUrl from "@assets/img/header_logo.svg";

const Header = () => {
  const {
    i18n,
    lngs,
    setCurLng,
    burger,
    onBurger,
    closeBurger,
  } = useContext(AppContext);
  const [langPopUp, setLangPopUp] = useState(false);
  const [activeLang, setActiveLang] = useState(
    lngs.filter(item => item.lng === i18n.resolvedLanguage)[0]
  );

  const toggleLangPopUp = () => {
    setLangPopUp(!langPopUp);
  };

  const onLng = curLng => {
    i18n.changeLanguage(curLng.lng);
    setCurLng(curLng.lng);
    toggleLangPopUp();
    setActiveLang(curLng);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <Logo
            classNames={"header__logo"}
            logoUrl={logoUrl}
            width={109}
            height={58}
            onClick={closeBurger}
          />
          <button
            className={clsx("header__burger", { active: burger })}
            onClick={onBurger}
          >
            <svg
              className="btn__angle"
              width={7}
              height={7}
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>
            <svg
              className="btn__angle"
              width={7}
              height={7}
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>
            <svg
              className="btn__angle"
              width={7}
              height={7}
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>
            <svg
              className="btn__angle"
              width={7}
              height={7}
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7H1V-3.57628e-07" strokeWidth="2" />
            </svg>
          </button>
          <div className={clsx("header__lang", { active: langPopUp })}>
            <button className="header__lang-btn" onClick={toggleLangPopUp}>
              {activeLang.text}
              <svg
                className="header__lang-btn-arrow"
                viewBox="0 0 9 6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 6L.603.75h7.794L4.5 6z" />
              </svg>
            </button>
            <div
              className="header__lang-overlay"
              onClick={toggleLangPopUp}
            ></div>
            <div className="header__lang-list">
              {lngs.map(lng => (
                <button
                  className={clsx("header__lang-list-btn", {
                    active: activeLang.lng === lng.lng,
                  })}
                  key={lng.lng}
                  onClick={() => onLng(lng)}
                >
                  {lng.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
